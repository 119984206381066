import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme.json";

const Wrapper = styled.div`
  width: 100%;
  background: rgba(40, 40, 45, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  padding-top: 48px;
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 72px;
  margin-bottom: 48px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    height: 96px;
    margin-bottom: 96px;
  }
`;

const Logo = styled.img`
  height: 100%;
`;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 350;
  margin-top: 48px;
  margin-bottom: 48px;
  color: rgba(255, 255, 255, 0.2);
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: ${theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const ItemColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

const ItemHeading = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 8px;
  color: white;
  white-space: nowrap;
  line-height: 1;
`;

const Item = styled(Link)`
  font-size: 14px;
  font-weight: 350;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  line-height: 1;
  &:hover{
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Footer = (props) => {

  return (
    <Wrapper>
      <LogoWrapper to="/">
        <Logo src="FDV-logo-inverted.png" />
      </LogoWrapper>
      <ItemWrapper>
        <ItemColumn>
          <ItemHeading>
            Coverage
          </ItemHeading>
          <Item to="/match-predictions">
            Match predictions
          </Item>
          <Item to="/league-predictions">
            League predictions
          </Item>
          <Item to="/fixture-guides">
            Fixture guides
          </Item>
          <Item to="/match-previews">
            Match previews
          </Item>
          <Item to="/match-reports">
            Match reports
          </Item>
          <Item to="/power-rankings">
            Power rankings
          </Item>
          <Item to="/team-analysis">
            Team analysis
          </Item>
          <Item to="/player-analysis">
            Player analysis
          </Item>
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Leagues
          </ItemHeading>
          <Item to="/premier-league">
            Premier League
          </Item>
          <Item to="/la-liga">
            La Liga
          </Item>
          <Item to="/bundesliga">
            Bundesliga
          </Item>
          <Item to="/serie-a">
            Serie A
          </Item>
          <Item to="/ligue-1">
            Ligue 1
          </Item>
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Product
          </ItemHeading>
          <Item to="/subscribe">
            Subscribe
          </Item>
          <Item to="/sign-up">
            Sign up
          </Item>
          <Item to="/sign-in">
            Sign in
          </Item>
        </ItemColumn>
        <ItemColumn>
          <ItemHeading>
            Company
          </ItemHeading>
          <Item to="/about">
            About
          </Item>
          <Item to="/terms-of-service">
            Terms of service
          </Item>
          <Item to="/privacy-policy">
            Privacy policy
          </Item>
        </ItemColumn>
      </ItemWrapper>
      <Copyright>
        © 2024 Hjartdal Studios LLC
      </Copyright>
    </Wrapper>
  )
}

export default Footer;
