import React from "react";
import { PageWrapper, FormWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-components";
import { FormInstructions, FormGrid } from "./form-elements";

const VerifyEmailExpiredLinkPage = (props) => {

  return (
    <PageWrapper>
      <FormWrapper>
        <Heading>
          Verify your email
        </Heading>
        <FormInstructions>
          This verification link has expired. Please <TextLink to="/sign-in">sign in</TextLink> so we can send you an email with another link to verify your email address. Please click on the link within the next 10 minutes to activate your account.
        </FormInstructions>
      </FormWrapper>
    </PageWrapper>
  )
}

export default VerifyEmailExpiredLinkPage;
