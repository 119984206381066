import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme.json";
import { FeatureText, SmallFeatureTitle } from "./text-components";

const CardWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  height: 450px;
  min-width: 312px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    min-width: 360px;
    height: 500px;
  }
`;

const Card = styled(Link)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.background_dark};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.05);
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    transform: scale(1);
    transition: 0.2s ease-in-out;
    ${CardWrapper}:hover & {
      transform: scale(1.02);
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: white;
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 28px;
  }
`;

const ArticleCard = (props) => {

  const { link } = props;

  return (
    <CardWrapper>
      <Card to={link}>
        <Text>
          See more
        </Text>
      </Card >
    </CardWrapper >
  )
}

export default ArticleCard;
