import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper, ContentWrapper } from "./wrapper-elements.js"
import theme from "./theme";

const PageTitle = styled.div`
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 48px;
  color: ${theme.color.text.dark};
  font-size: 10vw;
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 10vw;
    margin-bottom: 48px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    margin-bottom: 96px;
  }
`;

const PageDescription = styled.div`
  font-weight: 350;
  line-height: 1.7;
  color: ${theme.color.text.dark};
  font-size: 16px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    text-align: center;
    font-size: 18px;
    width: 50vw;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
  color: ${theme.color.text.dark};
  margin-bottom: 24px;
  font-size: 10vw;
  @media (min-width: ${theme.breakpoints.tablet}) {
    margin-bottom: 48px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    margin-bottom: 96px;
  }
`;

const Subtitle = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  color: ${theme.color.text.dark};
  margin-bottom: 24px;
  font-size: 18px;
  &::before {
    content: '';
    display: block;
    margin-top: -3px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -3px;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 24px;
    margin-bottom: 24px;
  };
`;

const Author = styled.div`
  position: relative;
  font-size: 15px;
  font-weight: 350;
  color: ${theme.color.text.dark};
  margin-bottom: 0px;
  line-height: 1.3;
  // &::before {
  //   content: '';
  //   display: block;
  //   margin-top: -3px;
  // }
  // &::after {
  //   content: '';
  //   display: block;
  //   margin-bottom: -3px;
  // }
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 16px;
  };
`;

const Date = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 350;
  line-height: 1.3;
  color: ${theme.color.text.light};
`;

const Tier = styled.div`
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 350;
  letter-spacing: 1.5px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 24px;
    &::before {
    content: '';
    display: block;
    margin-top: -1px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -2px;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 14px;
  };
`;

const Heading = styled.h2`
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${theme.color.text.dark};
  margin-top: 48px;
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 28px;
    margin-top: 24px;
    margin-bottom: 24px;
  };
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Subheading = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  color: ${props => props.$inverted ? "white" : theme.color.text.dark};
  margin-top: 12px;
  margin-bottom: 12px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  };
  // &::before {
  //   content: '';
  //   display: block;
  //   margin-top: -2px;
  // }
  // &::after {
  //   content: '';
  //   display: block;
  //   margin-bottom: -2px;
  // }
`;

const Paragraph = styled.p`
  width: 100%;
  font-weight: 350;
  line-height: 1.5;
  color: ${theme.color.text.normal};
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 24px;
  text-align: left;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
  @media (min-width: ${theme.breakpoints.tablet}}) {
    font-size: 16px;
  };
`;

const SectionTitleColumn = styled.div`
  font-weight: 600;
  font-size: 24px;
  margin-left: 12px;
  margin-right: 12px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 28px;
  }
`;

const SectionTitleRow = styled.div`
  font-weight: 600;
  font-size: 24px;
  margin-left: 24px;
  margin-right: 24px;
  line-height: 1.3;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-left: 12px;
    margin-left: 48px;
    font-size: 28px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    margin-left: 96px;
  }
`;

const SectionSubtitle = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: ${theme.color.text_light};
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 28px;
  }
`;

const LargeFeatureTitle = styled(Heading)`
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 32px;
`;

const SmallFeatureTitle = styled(Heading)`
  font-weight: 700;
  padding-top: ${theme.spacing.one_unit};
  padding-bottom: ${theme.spacing.one_unit};
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
`;

const FeatureText = styled.p`
  width: 100%;
  font-weight: 350;
  line-height: 1.6;
  color: ${theme.color.text.normal};
  font-size: 15px;
  margin-top: ${theme.spacing.one_unit};
  margin-bottom: ${theme.spacing.one_unit};
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Items = styled.ul`
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

const Item = styled.li`
  width: 100%;
  font-weight: 350;
  line-height: 1.6;
  color: ${props => props.$inverted ? "white" : theme.color.text.normal};
  font-size: 15px;
  text-align: ${props => props.$centered ? "center" : "left"};
  margin-bottom: 24px;
  &::before {
    content: '';
    display: block;
    margin-top: -6px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -6px;
  }
`;

const Dot = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`;

const Highlight = styled.span`
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  background: ${theme.color.primary};
  font-weight: 350;
  border-radius: 2px;
  color: white;
  white-space: nowrap;
`;

const TextLink = styled(Link)`
  font-weight: 600;
  color: ${theme.color.text.dark};
  padding-bottom: 1px;
  &:hover {
    border-bottom: 1.5px solid ${theme.color.text.dark};
  }
`;

export { PageTitle, PageDescription, Title, Subtitle, SectionTitleColumn, SectionTitleRow, SectionSubtitle, Heading, Subheading, Paragraph, Author, Date, Tier, Highlight, TextLink, LargeFeatureTitle, SmallFeatureTitle, Items, Item, FeatureText, Dot };
