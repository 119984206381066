import styled from "styled-components";
import theme from "./theme.json";

const FormElement = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 12px;
  padding-left: 12px;
`;

const FormInstructions = styled.div`
  width: 336;
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.paragraph};
  line-height: 1.5;
  margin-bottom: 16px;
  &::before {
    content: '';
    display: block;
    margin-top: -5px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -5px;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 100%;
`;

const FormMessage = styled.div`
  display: ${props => props.$hidden ? "none" : "flex"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${theme.color.text};
  width: 336px;
  border-radius: 8px;
  background-color: ${theme.color.background};
  line-height: 1.5;
`;

export { FormElement, FormInstructions, FormMessage, FormRow }; 
