import React from "react";
import { PageWrapper, FormWrapper } from "./wrapper-elements";
import { Heading } from "./text-components";
import { FormInstructions, FormGrid } from "./form-elements";

const VerifyEmailPage = (props) => {

  return (
    <PageWrapper>
      <FormWrapper>
        <Heading>
          Verify your email
        </Heading>
        <FormInstructions>
          We just sent you an email with a link to verify your email address. Please click on the link within the next 10 minutes to activate your account.
        </FormInstructions>
      </FormWrapper>
    </PageWrapper>
  )
}

export default VerifyEmailPage;
