import styled from "styled-components";
import theme from "./theme";

const BlockInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  padding-top: 24px;
  padding-bottom: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`;

const BlockTitle = styled.div`
  width: 50%;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  color: ${theme.color.title};
  margin-bottom: ${theme.spacing.text};
  width: calc(100% - 48px);
  @media (min-width: 768px) {
    font-size: 36px;
    max-width: 700px;
    margin-bottom: 48px;
  }
`;

const BlockParagraph = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: ${theme.spacing.text};
  width: calc(100% - 48px);
  @media (min-width: ${theme.breakpoints.tablet}) {
    max-width: 700px;
    font-size: 16px;
  }
`;

const SeparatorWrapper = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  background: ${props => props.bottomColor};
  transform: ${props => props.scale};
  margin-top: -8px;
  margin-bottom: -8px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
`;

const Block = (props) => {

  let { backgroundColor } = props;
  let color = "black";


  if (backgroundColor === "white") {
    backgroundColor = "white";
    color = theme.color.paragraph;
  }

  if (backgroundColor === "light") {
    backgroundColor = theme.color.background;
    color = theme.color.paragraph;
  }

  if (backgroundColor === "dark") {
    backgroundColor = theme.color.background_dark;
    color = "white";
  }

  if (backgroundColor === "primary") {
    backgroundColor = theme.color.primary;
    color = "white";
  }

  return (
    <BlockInner backgroundColor={backgroundColor} color={color}>
      {props.children}
    </BlockInner>
  )
}

const BlockSeparator = (props) => {

  let { shape, topColor, bottomColor, flipX, flipY } = props;

  if (flipX === undefined) {
    flipX = false;
  }

  if (flipY === undefined) {
    flipY = false;
  }

  var scale = "scale(1, 1)";

  if (flipX) {
    scale = "scale(-1, 1)";
  }

  if (flipY) {
    scale = "scale(1, -1)";
  }

  if (flipX && flipY) {
    scale = "scale(-1, -1)";
  }

  if (topColor === "light") {
    topColor = theme.color.background;
  }

  if (topColor === "dark") {
    topColor = theme.color.background_dark;
  }

  if (topColor === "primary") {
    topColor = theme.color.primary;
  }

  if (bottomColor === "light") {
    bottomColor = theme.color.background;
  }

  if (bottomColor === "dark") {
    bottomColor = theme.color.background_dark;
  }

  if (bottomColor === "primary") {
    bottomColor = theme.color.primary;
  }
  
  if (flipY) {
    let temp = topColor;
    topColor = bottomColor;
    bottomColor = temp;
  }

  let d;

  if (shape === 1) {
    d = "M 0 32 L 0 0 L 1024 0 L 1024 96 L 0 32 Z";
  }

  if (shape === 2) {
    d = "M 0 32 L 0 0 L 1024 0 L 1024 32 L 512 96 L 0 32 Z";
  }

  if (shape === 3) {
    d = "M 0 32 L 0 0 L 1024 0 L 1024 32 L 256 96 L 0 32 Z";
  }

  if (shape === 4) {
    d = "M 0 64 L 0 0 L 1024 0 L 1024 96 L 128 32 L 0 64 Z";
  }

  if (shape === 5) {
    d = "M 0 64 L 0 0 L 1024 0 L 1024 64 L 640 96 L 384 32 L 0 64 Z";
  }

  if (shape === 6) {
    d = "M 0 32 L 0 0 L 1024 0 L 1024 64 L 896 32 L 512 96 L 0 32 Z";
  }

  if (shape === 7) {
    d = "M 0 32 L 0 0 L 1024 0 L 1024 64 L 896 32 L 256 96 L 0 32 Z";
  }

  if (shape === 8) {
    d = "M 0 96 L 0 0 L 1024 0 L 1024 96 L 640 32 L 512 96 L 128 32 L 0 96 Z";
  }

  return (
    <SeparatorWrapper bottomColor={bottomColor} scale={scale} >
      <svg width="100%" height="100%" viewBox="0 0 1024 128">
        <path d={d} style={{ "fill": topColor }} />
      </svg>
    </SeparatorWrapper>
  );
};

export { Block, BlockTitle, BlockParagraph, BlockSeparator };
