import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Section from "./section";
import Button from "./button";
import { PageTitle, PageDescription } from "./text-components";
import { Block, BlockSeparator } from "./block-elements";
import { PageWrapper, BannerWrapper, TitleWrapper, ThreeColumnWrapper } from "./wrapper-elements";
import { LargeFeature, MediumFeature, SmallFeature, FeatureDivider } from "./feature-elements";
import { ChipSection, ChipTitle, ChipRow, ChipWrapper } from "./chip-components";
import theme from "./theme.json";

const ArticlesPage = (props) => {

  const { articleData, userData, title, byPostType, byLeague } = props;

  const titleBlock =
    <Block backgroundColor="white">
      <TitleWrapper>
        <PageTitle>
          {title}
        </PageTitle>
      </TitleWrapper>
    </Block>

  let page =
    <PageWrapper>
      {titleBlock}
    </PageWrapper>

  if (articleData) {

    if (byPostType) {

      page =
        <PageWrapper>
          {titleBlock}
          <BlockSeparator shape={4} topColor="white" bottomColor="light" flipY={true} />
          <Block backgroundColor="light">
            {/* <ChipSection>
              <ChipTitle>
                Coverage
              </ChipTitle>
              <ChipRow>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/match-predictions">
                    Match predictions
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/league-predictions">
                    League predictions
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/fixture-guides">
                    Fixture guides
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/match-previews">
                    Match previews
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/match-reports">
                    Match reports
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/power-rankings">
                    Power rankings
                  </Button>
                </ChipWrapper>
              </ChipRow>
            </ChipSection> */}
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "Match predictions";
                })
              }
              userData={userData}
              title="Match predictions"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "League predictions";
                })
              }
              userData={userData}
              title="League predictions"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "Fixture guide";
                })
              }
              userData={userData}
              title="Fixture guides"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "Match preview";
                })
              }
              userData={userData}
              title="Match previews"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "Match report";
                })
              }
              userData={userData}
              title="Match reports"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.type === "Power rankings";
                })
              }
              userData={userData}
              title="Power rankings"
              grid={true}
            />
          </Block>
          <BlockSeparator shape={7} topColor="light" bottomColor="dark" />
        </PageWrapper>
    }

    if (byLeague) {

      page =
        <PageWrapper>
          {titleBlock}
          <BlockSeparator shape={4} topColor="white" bottomColor="light" flipY={true} />
          <Block backgroundColor="light">
            {/* <ChipSection>
              <ChipTitle>
                Leagues
              </ChipTitle>
              <ChipRow>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/premier-league">
                    Premier League
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/la-liga">
                    La Liga
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/bundesliga">
                    Bundesliga
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/serie-a">
                    Serie A
                  </Button>
                </ChipWrapper>
                <ChipWrapper>
                  <Button variant="outlined" corners="pill" small to="/ligue-1">
                    Ligue 1
                  </Button>
                </ChipWrapper>
              </ChipRow>
            </ChipSection> */}
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.league === "Premier League";
                })
              }
              userData={userData}
              title="Premier League"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.league === "La Liga";
                })
              }
              userData={userData}
              title="La Liga"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.league === "Bundesliga";
                })
              }
              userData={userData}
              title="Bundesliga"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.league === "Serie A";
                })
              }
              userData={userData}
              title="Serie A"
              grid={true}
            />
            <Section
              articleData={
                articleData.filter((item) => {
                  return item.league === "Ligue 1";
                })
              }
              userData={userData}
              title="Ligue 1"
              grid={true}
            />
          </Block>
          <BlockSeparator shape={6} topColor="light" bottomColor="dark" />
        </PageWrapper>
    }
  }

  return (
    page
  )
}

export default ArticlesPage;
