
import styled from "styled-components";
import theme from "./theme";

const ChipSection = styled.div`
  display: none;
  @media (min-width: ${theme.breakpoints.tablet}) {
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 100%;
    font-size: 28px;
    margin-bottom: 48px;
    padding-left: calc(48px + 12px);
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    padding-left: calc(96px + 12px);
  }
`;

const ChipTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const ChipRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ChipWrapper = styled.div`
  margin-right: 12px;
  margin-bottom: 12px;
`;

export { ChipSection, ChipTitle, ChipRow, ChipWrapper };
