import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ArticleCard from "./article-card";
import ButtonCard from "./button-card";
import Button from "./button";
import { SectionWrapper, GridWrapper, GridRowWrapper, TitleWrapper } from "./wrapper-elements";
import { FeatureDivider } from "./feature-elements";
import { SectionTitleColumn, SectionTitleRow, SectionSubtitle } from "./text-components";
import theme from "./theme.json";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Section = (props) => {

  const { id, articleData, userData, title, subtitle, link, count, grid } = props;

  const { width } = useWindowDimensions();

  let selectedData = articleData.slice(0, count);

  if (count && width < theme.breakpoints.tablet) {
    selectedData = articleData.slice(0, 4);
  }

  if (count && width >= theme.breakpoints.tablet && width < theme.breakpoints.computer) {
    if (count % 2 === 0) {
      selectedData = articleData.slice(0, count);
    } else {
      selectedData = articleData.slice(0, count - 1);
    }
  }

  if (count && width >= theme.breakpoints.computer) {
    selectedData = articleData.slice(0, count);
  }

  let moreButton;
  if (link) {
    moreButton =
      <ButtonCard link={link} />
  }

  let subtitleComponent;
  if (subtitle) {
    subtitleComponent =
      <SectionSubtitle>
        {subtitle}
      </SectionSubtitle>
  }

  let titleComponent;
  if (title) {
    if (grid) {
      titleComponent =
        <SectionTitleColumn>
          {title} {subtitleComponent}
        </SectionTitleColumn>
    } else {
      titleComponent =
        <SectionTitleRow>
          {title} {subtitleComponent}
        </SectionTitleRow>
    }
  }

  let cards;
  if (grid) {
    cards =
      <GridWrapper>
        {selectedData.map((item, i) => {
          return (
            <ArticleCard key={i} articleData={item} userData={userData} i={i} />
          )
        })}
        {moreButton}
      </GridWrapper>
  } else {
    cards =
      <GridRowWrapper>
        {selectedData.map((item, i) => {
          return (
            <ArticleCard key={i} articleData={item} userData={userData} i={i} />
          )
        })}
        {moreButton}
      </GridRowWrapper>
  }

  return (
    <SectionWrapper id={id}>
      {titleComponent}
      {cards}
    </SectionWrapper>
  )
}

export default Section;
