import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MenuButton from "./menu-button";
import Button from "./button";
import theme from "./theme.json";

const Sidebar = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 1100;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: unset;
  width: unset;
  height: 96px;
  padding-right: 24px;
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: ${theme.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: unset;
    width: 96px;
    height: unset;
    padding-right: 0px;
    padding-top: 0px;
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  z-index: 1500;
  top: 96px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 48px;
  padding-left: 48px;
  background-color: ${theme.color.background};
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.open ? "1" : "0"};
  overflow-x: hidden;
  pointer-events: ${props => props.open ? "unset" : "none"};
  @media (min-width: ${theme.breakpoints.tablet}) {
    top: 0px;
    justify-content: center;
    padding-top: 0px;
    padding-left: 144px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    padding-left: 192px;
  }
`;

const ItemLarge = styled(Link)`
  font-size: 28px;
  font-weight: 700;
  color: ${theme.color.text.dark};
  margin-bottom: 12px;
  padding-bottom: 3px;
  cursor: pointer;
  transition: border-bottom 0.2s ease-in-out;
  border-bottom: solid 3px #00000000;
  &:hover {
    border-bottom: solid 3px #000000;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 40px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ItemSpacer = styled.div`
  min-height: 32px;
`;

const ItemSmall = styled(Link)`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.color.text.dark};
  margin-bottom: 8px;
  padding-bottom: 3px;
  cursor: pointer;
  transition: border-bottom 0.2s ease-in-out;
  border-bottom: solid 1.5px #00000000;
  &:hover {
    border-bottom: solid 1.5px #000000;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 18px;
  }
`;

const Group = styled.div`
  position: relative;
  z-index: 1000;
  display: none;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 96px;
`;

const Logo = styled.img`
  height: 60px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    height: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 24px;
  margin-right: 0px;
`;

const MenuButtonWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 2000;
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Header = styled.div`
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 96px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  @media (min-width: ${theme.breakpoints.tablet}) {
    position: absolute;
    align-items: center;
    height: 144px;
    padding-left: 8%;
    padding-right: 8%;
    justify-content: space-between;
    border-bottom: unset;
  }
`;

const Menu = (props) => {

  const { userData, signOut } = props;
  const [menuOpen, setMenuOpen] = React.useState(false);

  let buttons;

  if (userData) {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button variant="outlined" corners="pill" to="/subscribe">
            Subscribe
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/account">
            Account
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" onClick={signOut}>
            Sign out
          </Button>
        </ButtonWrapper>
      </Group>
  } else {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button variant="outlined" corners="pill" to="/subscribe">
            Subscribe
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/sign-up">
            Sign up
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/sign-in">
            Sign in
          </Button>
        </ButtonWrapper>
      </Group>
  }

  let items;

  if (userData) {
    items =
      <ItemWrapper>
        <ItemSmall to="/subscribe" onClick={() => setMenuOpen(false)} >
          Subscribe
        </ItemSmall>
        <ItemSmall to="/account" onClick={() => setMenuOpen(false)} >
          Account
        </ItemSmall>
        <ItemSmall as="div" onClick={() => {
          signOut()
          setMenuOpen(false)
        }}>
          Sign out
        </ItemSmall>
      </ItemWrapper>
  } else {
    items =
      <ItemWrapper>
        <ItemSmall to="/subscribe" onClick={() => setMenuOpen(false)} >
          Subscribe
        </ItemSmall>
        <ItemSmall to="/sign-up" onClick={() => setMenuOpen(false)} >
          Sign up
        </ItemSmall>
        <ItemSmall to="/sign-in" onClick={() => setMenuOpen(false)} >
          Sign in
        </ItemSmall>
      </ItemWrapper>
  }

  return (
    <div>
      {/* <Sidebar>
        <MenuButtonWrapper>
          <MenuButton open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
        </MenuButtonWrapper>
      </Sidebar> */}
      <Header>
        <LogoWrapper to="/">
          <Logo src="FDV-logo.png" />
        </LogoWrapper>
        {buttons}
        <MenuButtonWrapper>
          <MenuButton open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
        </MenuButtonWrapper>
      </Header>
      <Drawer open={menuOpen}>
        <ItemLarge to="/" onClick={() => setMenuOpen(false)} >
          Home
        </ItemLarge>
        <ItemLarge to="/coverage" onClick={() => setMenuOpen(false)} >
          Coverage
        </ItemLarge>
        <ItemLarge to="/leagues" onClick={() => setMenuOpen(false)} >
          Leagues
        </ItemLarge>
        <ItemLarge to="/methodology" onClick={() => setMenuOpen(false)} >
          Methodology
        </ItemLarge>
        <ItemSpacer />
        {items}
        <ItemSpacer />
        <ItemSmall to="/about" onClick={() => setMenuOpen(false)} >
          About
        </ItemSmall>
        <ItemSmall to="/terms-of-service" onClick={() => setMenuOpen(false)} >
          Terms of service
        </ItemSmall>
        <ItemSmall to="/privacy-policy" onClick={() => setMenuOpen(false)} >
          Privacy policy
        </ItemSmall>
      </Drawer>
    </div>
  );
}

export default Menu;
