import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme.json";

// const Wrapper = styled(Link)`
//   position: relative;
//   box-sizing: border-box;
//   width: 156px;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: center;
//   margin-top: 12px;
//   margin-bottom: 12px;
//   margin-left: 12px;
//   margin-right: 12px;
//   transform: scale(1);
//   transition: ease-in-out 0.2s;
//   ${Wrapper}:hover & {
//     transform: scale(1.03);
//   }
// `;

const Wrapper = styled(Link)`
  box-sizing: border-box;
  width: 154px;
  height: 154px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  // background-color: ${theme.color.background_dark_hover};
  border-radius: 16px;
  margin: 12px;
  cursor: pointer;
  transform: scale(1);
  transition: ease-in-out 0.2s;
  opacity: 1;
  &:hover {
    // transform: scale(1.05);
    opacity: 0.5;
  }
`;

const Image = styled.img`
  height: 60%;
  opacity: 1;
  margin-bottom: 24px;
`;

const Text = styled.div`
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  transition: 0.2s ease-in-out;
  color: white;
`;

const Card = (props) => {

  const { text, image, to } = props;

  let imageComponent;
  if (image) {
    imageComponent =
      <Image src={image} />
  }

  return (
    <Wrapper to={to}>
      {imageComponent}
      <Text>
        {text}
      </Text>
    </Wrapper>
  )
}

export default Card;
