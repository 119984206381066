import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "./theme.json";

const Chip = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.color.paragraph};
  // background-color: ${theme.color.background};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  margin-right: 10px;
  // margin-bottom: 12px;
  border-radius: 50px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  background-color: rgba(245, 245, 247);
  // border: 1.5px solid rgba(40, 40, 45, 1);
  transition: 0.2s ease-in-out;
  &:hover {
    color: rgba(245, 245, 247);
    background-color: rgba(40, 40, 45, 1);
  }
`;

export default Chip;
