import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "./theme.json";
import { FeatureText, SmallFeatureTitle } from "./text-components";

const CardWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  height: 450px;
  max-height: 450px;
  width: 312px;
  min-width: 312px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    min-width: 360px;
    height: 500px;
    max-height: 500px;
  }
`;

const Card = styled(Link)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  @media (min-width: ${theme.breakpoints.tablet}) {
    transform: scale(1);
    transition: 0.2s ease-in-out;
    ${CardWrapper}:hover & {
      transform: scale(1.02);
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: calc(272px - 24px);
  margin-bottom: 24px;
  height: 60%;
  width: 100%;
  overflow: hidden;
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  box-sizing: border-box;
  height: 90%;
`;

const MapWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Map = styled.img`
  height: 90%;
`;

// const ImageWrapper = styled.div`
//   position: relative;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   height: 200px;
//   width: 200px;
//   background-color: ${theme.color.background};
//   border-radius: 8px;
// `;

const MatchWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const TeamCrestWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 144px;
  // margin-left: -12px;
  // margin-right: -12px;
`;

const TeamName = styled.div`
  position: absolute;
  top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
`;

const TeamCrest = styled.img`
  object-fit: cover;
  height: 100%;
`;

const VersusText = styled.div`
  position: absolute;
  top: 68px;
  font-weight: 600;
  font-size: 16px;
  color: ${theme.color.text.dark};
`;

const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40%;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  // @media (min-width: ${theme.breakpoints.tablet}) {
  //   padding-top: 24px;
  //   padding-bottom: 24px;
  //   padding-left: 24px;
  //   padding-right: 24px;
  // }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  // text-align: center;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 600;
  color: ${theme.color.text_dark};
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 28px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  // text-align: center;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.text_dark};
  @media (min-width: ${theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`;

const Subscription = styled.div`
  color: ${theme.color.text_dark};
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Lock = styled.img`
  margin-right: 4px;
  height: 12px;
`;

const ArticleCard = (props) => {

  const { articleData, userData, i } = props;

  let unlocked = false;

  if (articleData.subscription === "Free") {
    unlocked = true;
  }

  if (userData) {
    if (articleData.subscription === "The Fan" && (userData.basic || userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.subscription === "The Insider" && (userData.standard || userData.premium)) {
      unlocked = true;
    }
    if (articleData.subscription === "The Analyst" && userData.premium) {
      unlocked = true;
    }
  }

  let subscription =
    <Subscription to="/subscribe">
      <Lock src="/small-icons/locked.svg" /> {articleData.subscription}
    </Subscription>

  if (unlocked) {
    subscription =
      <Subscription to="/subscribe">
        {articleData.subscription}
      </Subscription>
  }

  if (articleData.subscription === "Free") {
    subscription =
      <Subscription to="/subscribe">
        FREE
      </Subscription>
  }

  let iconFile;

  if (articleData.type == "Match predictions") {
    if (i % 2 == 0) {
      iconFile = "/post-icons/match-predictions-icon-1.svg";
    } else {
      iconFile = "/post-icons/match-predictions-icon-2.svg";
    }
  }

  if (articleData.type == "League predictions") {
    if (i % 2 == 0) {
      iconFile = "/post-icons/league-predictions-icon-1.svg";
    } else {
      iconFile = "/post-icons/league-predictions-icon-2.svg";
    }
  }

  if (articleData.type == "Fixture guide") {
    iconFile = "/post-icons/fixture-guide-icon.svg";
  }

  if (articleData.type == "Match preview") {
    if (i % 2 == 0) {
      iconFile = "/post-icons/match-preview-icon-1.svg";
    } else {
      iconFile = "/post-icons/match-preview-icon-2.svg";
    }
  }

  if (articleData.type == "Match report") {
    if (i % 2 == 0) {
      iconFile = "/post-icons/match-report-icon-1.svg";
    } else {
      iconFile = "/post-icons/match-report-icon-2.svg";
    }
  }

  if (articleData.type == "Power rankings") {
    iconFile = "/post-icons/power-rankings-icon.svg";
  }

  if (articleData.type == "Team analysis") {
    iconFile = "/post-icons/team-analysis-icon.svg";
  }

  let map;

  if (articleData.league === "Premier League") {
    map =
      <Map src="./maps/england-map-light.svg" />
  }

  if (articleData.league === "La Liga") {
    map =
      <Map src="/maps/spain-map-light.svg" />
  }

  if (articleData.league === "Bundesliga") {
    map =
      <Map src="/maps/germany-map-light.svg" />
  }

  if (articleData.league === "Serie A") {
    map =
      <Map src="/maps/italy-map-light.svg" />
  }

  if (articleData.league === "Ligue 1") {
    map =
      <Map src="/maps/france-map-light.svg" />
  }

  return (
    <CardWrapper>
      <Card to={"/" + articleData.id}>
        <Content>
          {subscription}
          <Title>
            {articleData.type}
          </Title>
          <Subtitle>
            {articleData.title}
          </Subtitle>
        </Content>
        <ImageWrapper>
          <Image src={iconFile} />
          <MapWrapper>
            {map}
          </MapWrapper>
        </ImageWrapper>
        {/* <ImageWrapper>
          <MatchWrapper>
            <TeamCrestWrapper>
              <TeamCrest src="home-crest.svg" />
              <TeamName>
                MCI
              </TeamName>
            </TeamCrestWrapper>
            <VersusText>
              vs
            </VersusText>
            <TeamCrestWrapper>
              <TeamCrest src="away-crest.svg" />
              <TeamName>
                ARS
              </TeamName>
            </TeamCrestWrapper>
          </MatchWrapper>
        </ImageWrapper> */}
      </Card >
    </CardWrapper >
  )
}

export default ArticleCard;
