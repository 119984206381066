import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import Section from "./section";
import { Block, BlockSeparator } from "./block-elements"
import { PageWrapper, TitleWrapper, TitleBannerWrapper, GridWrapper } from "./wrapper-elements";
import { PageTitle, PageDescription, Highlight } from "./text-components";
import theme from "./theme";
// import Dialog from "./dialog";
import Axios from "axios";

const data = [
  {
    "name": "The Fan",
    "price_id_monthly": "price_1Nv0FpJEOuFoLCZqi1n6uqEd",
    "price_id_yearly": "price_1Nv47LJEOuFoLCZqiDNzkGxl",
    "price_monthly": "$2",
    "price_yearly": "$20",
    "button_label": "Subscribe"
  },
  {
    "name": "The Insider",
    "price_id_monthly": "price_1NxIhfJEOuFoLCZq38HXdBN3",
    "price_id_yearly": "price_1OFJZPJEOuFoLCZq9cE3vAwv",
    "price_monthly": "$5",
    "price_yearly": "$50",
    "button_label": "Subscribe"
  },
  {
    "name": "The Analyst",
    "price_id_monthly": "price_1Nv4aoJEOuFoLCZqWGFjN6iT",
    "price_id_yearly": "price_1Nv4aoJEOuFoLCZqt2DYE4gG",
    "price_monthly": "$10",
    "price_yearly": "$100",
    "button_label": "Subscribe"
  },
];

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 96px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ToggleButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  color: ${props => props.selected ? theme.color.white : theme.color.button_dark};
  background-color: ${props => props.selected ? theme.color.button_dark : theme.color.background};
  border: 1.5px solid ${theme.color.line_dark};
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  width: 156px;
  text-align: center;
  transition-duration: 0.2s;
  &:hover {
    background-color: ${props => props.selected ? theme.color.button_dark_hover : theme.color.button_dark};
    border-color: ${props => props.selected ? theme.color.button_dark_hover : theme.color.button_dark};
    color: ${props => props.selected ? theme.color.white : theme.color.white};
  }
`;

const ToggleCaption = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 350;
  padding-top: 16px;
  color: ${theme.color.primary.normal};
  text-align: center;
  padding-left: 178px;
  margin-bottom: 48px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  transform: scale(1);
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${theme.color.caption};
  background-color: #ffffff;
  // border-radius: 16px;
  padding-top: 48px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  width: 336px;
  height: 100%;
  cursor: default;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
`;

const ProductName = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: calc(24px - 4px);
  line-height: 1;
  color: ${theme.color.text.dark};
`;

const ProductPrice = styled.div`
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 1;
  color: ${theme.color.text.dark};
`;

const ProductPriceText = styled.span`
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.paragraph};
`;

const FeatureList = styled.div`
  // margin-bottom: 50px;
`;

const ProductFeature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
`;

const FeatureText = styled.div`
  font-size: 14px;
  font-weight: 350;
  margin-top: 0px;
  line-height: 1.3;
  color: ${theme.color.paragraph};
`;

const FeatureIcon = styled.img`
  height: 18px;
  margin-right: 12px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 48px;
`;

const Footnote = styled.div`
  font-size: 14px;
  font-weight: 350;
  margin-top: 0px;
  line-height: 1.3;
  color: ${theme.color.text.light};
  width: 100%;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${theme.color.text.dark};
`;

const Italic = styled.span`
  font-style: italic;
`;

const SubscribePage = (props) => {

  const { API, articleData, userData } = props;

  // let [prices, setPrices] = React.useState();

  let [yearly, setYearly] = React.useState(false);

  const navigate = useNavigate();

  const goToCheckout = (price_id, userData) => {
    if (userData) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        withCredentials: true,
        data: {
          "priceId": price_id
        }
      }
      Axios.post(API + "/payments/create-checkout-session", config)
        .then(response => {
          window.location.href = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      navigate("/sign-up")
    }
  }

  const Feature = (props) => {
    return (
      <ProductFeature>
        <FeatureIcon src="/small-icons/circle-check-outlined.svg" />
        <FeatureText>
          {props.children}
        </FeatureText>
      </ProductFeature>
    )
  }

  const ProductCard = (props) => {

    const { name, priceId, price, yearly } = props;

    // goToCheckout(priceId, userData)

    const card =
      <CardWrapper>
        <Card>
          <ProductName>
            {name}
          </ProductName>
          <ProductPrice>
            {price}<ProductPriceText> / {yearly ? "year" : "month"}</ProductPriceText>
          </ProductPrice>
          <ButtonWrapper>
            <Button variant="outlined" corners="pill" onClick={() => {}}>
              Coming soon
            </Button>
          </ButtonWrapper>
          <FeatureList>
            {props.children}
          </FeatureList>
        </Card>
      </CardWrapper>

    return card;
  }

  return (
    <PageWrapper>
      <Block backgroundColor="white">
        <TitleWrapper>
          <PageTitle>
            Clear, intuitive insights
          </PageTitle>
          <PageDescription>
            Advanced football analytics have revolutionized how football is played and analyzed. With our intuitive data visualization, you get all the game-changing insights without all the numbers. Subscribe today for as little as <Highlight>$2 a month!</Highlight>
          </PageDescription>
        </TitleWrapper>
      </Block>
      <BlockSeparator shape={2} topColor="white" bottomColor="light" />
      <Block backgroundColor="light">
        <PricingWrapper>
          <ToggleWrapper>
            <ToggleButton selected={!yearly} onClick={() => setYearly(false)}>
              Monthly
            </ToggleButton>
            <ToggleButton selected={yearly} onClick={() => setYearly(true)}>
              Yearly
            </ToggleButton>
          </ToggleWrapper>
          <ToggleCaption>
            <Highlight>Save 17%!</Highlight>
          </ToggleCaption>
          <GridWrapper>
            <ProductCard data={data[0]}
              name={data[0].name}
              price={yearly ? data[0].price_yearly : data[0].price_monthly}
              yearly={yearly}
              priceId={yearly ? data[0].price_id_yearly : data[0].price_id_monthly}
            >
              <Feature>
                <Bold>Match predictions:</Bold> industry-leading AI-powered score predictions for upcoming matches
              </Feature>
              <Feature>
                <Bold>League predictions:</Bold> AI-powered league simulations, predicted final table, title and relegation predictions
              </Feature>
              <Feature>
                <Bold>Fixture guides:</Bold> upcoming fixtures, recommended matches, upset watch, expected competitiveness, and more
              </Feature>
              <Feature>
                Selected match previews and match reports from <Bold>The Insider</Bold> package
              </Feature>
              <Feature>
                7-day free trial
              </Feature>
            </ProductCard>
            <ProductCard data={data[1]}
              name={data[1].name}
              price={yearly ? data[1].price_yearly : data[1].price_monthly}
              yearly={yearly}
              priceId={yearly ? data[1].price_id_yearly : data[1].price_id_monthly}
            >
              <Feature>
                All content from <Bold>The Fan</Bold> package plus match previews, match reports, and power rankings!
              </Feature>
              <Feature>
                <Bold>Match previews:</Bold> AI-powered score predictions, HGIMT team ratings, recent form, fixture histories, and more
              </Feature>
              <Feature>
                <Bold>Match reports:</Bold> timeline, AI-powered HGIMT player ratings, combined XI, attacking threat analysis, player creativity analysis, and more
              </Feature>
              <Feature>
                <Bold>Power rankings:</Bold> AI-powered HGIMT team ratings, power rankings, analysis of week-to-week movements and longer-term trends
              </Feature>
              <Feature>
                Selected content from <Bold>The Analyst</Bold> package
              </Feature>
              <Feature>
                7-day free trial
              </Feature>
            </ProductCard>
            <ProductCard data={data[2]}
              name={data[2].name}
              price={yearly ? data[2].price_yearly : data[2].price_monthly}
              yearly={yearly}
              priceId={yearly ? data[2].price_id_yearly : data[2].price_id_monthly}
            >
              <Feature>
                All content from <Bold>The Insider</Bold> package plus in-depth analyis using intuitive data visualization!
              </Feature>
              <Feature>
                <Bold>Team analysis:</Bold> a visual analysis of a team's recent performances — formations, possession, passing, pressing, counter-attacking, set-pieces, and more
              </Feature>
              <Feature>
                <Bold>Player analysis:</Bold> a visual analysis of a player's recent performances — shot and assist quality, passing quality and creativity, tackles and blocks, dicipline, and more
              </Feature>
              <Feature>
                7-day free trial
              </Feature>
            </ProductCard>
          </GridWrapper>
        </PricingWrapper>
        <Section
          articleData={articleData}
          userData={userData}
          title="Example posts"
          count={9}
          grid={true}
        />
      </Block>
      <BlockSeparator shape={3} topColor="light" bottomColor="dark" flipY={true} />
    </PageWrapper>
  );
};

export default SubscribePage;
