import React from "react";
import { PageWrapper, FormWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-components";
import { FormInstructions } from "./form-elements";

const WelcomePage = (props) => {

  return (
    <PageWrapper>
      <FormWrapper>
        <Heading>
          Welcome!
        </Heading>
        <FormInstructions>
          Thanks for verifying your email adresss. Your account is now active. Please <TextLink to="/sign-in">sign in</TextLink> to start reading.
        </FormInstructions>
      </FormWrapper>
    </PageWrapper>
  )
}

export default WelcomePage;
