import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import TextBox from "./text-box";
import Button from "./button";
import { FormElement, FormInstructions, FormMessage } from "./form-elements";
import { PageWrapper, FormWrapper } from "./wrapper-elements";
import { Heading, TextLink } from "./text-components";
import { Block, BlockSeparator } from "./block-elements";
import theme from "./theme";

const SignUpForm = (props) => {

  const { API, saveUserData, to } = props;

  let [inviteCode, setInviteCode] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [passwordCheck, setPasswordCheck] = useState("");
  let [message, setMessage] = useState("");

  const navigate = useNavigate();

  const signUp = () => {
    if (password === passwordCheck) {
      setMessage("");
      Axios({
        method: "post",
        url: API + "/user/sign-up",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          "invite_code": inviteCode,
          "first_name": firstName,
          "last_name": lastName,
          "email": email,
          "password": password,
        }
      })
        .then(response => {
          console.log(response.data)
          if (response.data.error !== 'Please enter a valid invite code.') {
            navigate(to);
          }
        })
        .catch(error => {
          console.log(error.response.data.error)
          setMessage(error.response.data.error);
        });
    } else {
      setMessage("The passwords you entered don't match. Please check them and try again.");
    }
  }

  return (
    <PageWrapper>
      <Block backgroundColor="white">
        <FormWrapper>
          <Heading>
            Sign up
          </Heading>
          <FormInstructions>
            <TextLink to="/">Footballdataviz.com</TextLink> is currently invite-only. If you have an invite code, but haven't signed up yet, please sign up below to get started.
          </FormInstructions>
          <FormElement>
            <TextBox type="text" value={inviteCode} label="Invite Code" note=
              "Please enter a valid invite code." onChange={setInviteCode} />
          </FormElement>
          <FormElement>
            <TextBox type="text" value={firstName} label="First name" onChange={setFirstName} />
          </FormElement>
          <FormElement>
            <TextBox type="text" value={lastName} label="Last name" onChange={setLastName} />
          </FormElement>
          <FormElement>
            <TextBox type="email" value={email} label="Email" onChange={setEmail} />
          </FormElement>
          <FormElement>
            <TextBox type="password" value={password} label="Password" note="Passwords must have at least 8 characters and have at least one lower case letter, one uppercase letter, one number, and one special character." onChange={setPassword} />
          </FormElement>
          <FormElement>
            <TextBox type="password" value={passwordCheck} label="Confirm password" onChange={setPasswordCheck} />
          </FormElement>
          <FormInstructions>
            By signing up, you agree to our <TextLink to="/terms-of-service">Terms of Service</TextLink> and our <TextLink to="/privacy-policy">Privacy Policy</TextLink>.
          </FormInstructions>
          <FormElement>
            <Button variant="outlined" corners="pill" onClick={signUp}>
              Sign up
            </Button>
          </FormElement>
          <FormMessage style={{ "display": message === "" ? "none" : "flex" }}>
            {message}
          </FormMessage>
        </FormWrapper>
      </Block>
      <BlockSeparator shape={3} topColor="white" bottomColor="dark" flipY={true} />
    </PageWrapper>
  )
}

export default SignUpForm;
