import React, { useEffect } from "react";
import styled from "styled-components";
import { Block, BlockSeparator } from "./block-elements";
import { PageWrapper, ArticleWrapper, PlotWrapper, TitleWrapper } from "./wrapper-elements";
import { Title, Subtitle, Author, Heading, Paragraph } from "./text-components";
import Button from "./button";
import Axios from "axios";
import Section from "./section";
import NavigationDots from "./nav-dots";
import theme from "./theme.json";

const Plot = styled.img`
  position: relative;
  display: block;
  width: 100%;
`;

const ArticlePage = (props) => {

  const { userData, API, articleId, selectedArticleData, articleData } = props;

  const [data, setData] = React.useState();
  const [message, setMessage] = React.useState();

  const navData = [
    { id: "block-1", label: "Power ratings" },
    { id: "block-2", label: "Predicted goals" },
    { id: "block-3", label: "Predicted scoreline" },
    { id: "block-4", label: "Recent form" },
    { id: "block-5", label: "Fixture history" },
  ];

  const getData = () => {
    let headers;
    if (userData) {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        'authorization': userData.token,
      }
    } else {
      headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    }
    Axios({
      method: "get",
      url: API + "/content/post-data/" + articleId,
      headers: headers
    })
      .then(response => {
        setMessage("");
        console.log(response)
        setData(response.data);
      })
      .catch(error => {
        if (error.response) {
          // setMessage(error.response.data.error);
        }
      });
  }

  useEffect(() => {
    getData();
  }, [])

  const titleBlock =
    <Block backgroundColor="white">
      <TitleWrapper>
        <Title>
          {selectedArticleData.type}
        </Title>
        <Subtitle>
          {selectedArticleData.title}
        </Subtitle>
        <Author>
          By {selectedArticleData.author}
        </Author>
      </TitleWrapper>
    </Block>

  const recommendations =
    <Section
      articleData={
        articleData.filter((item) => {
          return item.league === selectedArticleData.league;
        })
      }
      userData={userData}
      title="Recomended posts"
      count={6}
      grid={true}
    />

  let content;
  if (data) {

    let plots;
    if (data.plots instanceof Array) {
      plots = data.plots.map((item, i) => {
        return (
          <PlotWrapper>
            <Plot src={item} />
          </PlotWrapper>
        )
      })
    } else {
      plots =
        <PlotWrapper>
          <Plot src={data.plots} />
        </PlotWrapper>
    }

    content =
      <PageWrapper>
        {titleBlock}
        <BlockSeparator shape={2} topColor="white" bottomColor="light" flipX={true} />
        <Block backgroundColor="light">
          <ArticleWrapper>
            {plots}
          </ArticleWrapper>
          {recommendations}
        </Block>
        <BlockSeparator shape={3} topColor="light" bottomColor="dark" flipY={true} />
      </PageWrapper>

  } else {
    content =
      <PageWrapper>
        {titleBlock}
        <BlockSeparator shape={2} topColor="white" bottomColor="light" flipX={true} />
        <Block backgroundColor="light">
          <ArticleWrapper style={{ marginBottom: "144px" }}>
            <Heading>
              Subscribe today!
            </Heading>
            <Paragraph>
              This article is available to members who have a subscription. Subscribe today to enjoy our independent football data journalism! Starting at only $2 a month.
            </Paragraph>
            <Button variant='outlined' corners='pill' to='/subscribe'>
              Subscribe
            </Button>
          </ArticleWrapper>
          {/* <NavigationDots data={navData} /> */}
          {recommendations}
        </Block>
        <BlockSeparator shape={3} topColor="light" bottomColor="dark" flipY={true} />
      </PageWrapper>
  }

  return (
    content
  )
};

export default ArticlePage;
