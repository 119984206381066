import styled from "styled-components";
import { Block } from "./block-elements";
import { PageWrapper, ArticleWrapper, TitleWrapper } from "./wrapper-elements";
import { PageTitle, Paragraph } from "./text-components";
import theme from "./theme.json";

const AboutPage = () => {

  return (
    <PageWrapper>
      <Block backgroundColor="white">
        <TitleWrapper>
          <PageTitle>
            About
          </PageTitle>
        </TitleWrapper>
      </Block>
      <Block backgroundColor="light">
        <ArticleWrapper>
          <Paragraph>
            <b>Footballdataviz.com</b> publishes quantitative analysis and data visualizations. We currently cover the Premier League, La Liga, the Bundesliga, Serie A, and Ligue 1.
          </Paragraph>
          <Paragraph>
            We publish industry-leading AI-powered predictions for upcoming matches and articles featuring data visualizations and quantitative analysis about leagues, matches, and players.
          </Paragraph>
          <Paragraph>
            <b>Footballdataviz.com</b> is owned by Hjartdal Studios LLC, an app development company. Hjartdal Studios LLC was founded by Josh Fjelstul, PhD. Josh is a data scientist and machine learning engineer. He has a PhD in Political Science from Emory University (2019).
          </Paragraph>
        </ArticleWrapper>
      </Block>
    </PageWrapper>
  );
};

export default AboutPage;
