import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import theme from "./theme";

const Group = styled.div`
  position: relative;
  z-index: 1000;
  display: none;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const DrawerWrapper = styled.div`
  display: ${props => props.open ? "flex" : "none"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
`;

const LogoWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 96px;
  // @media (min-width: ${theme.breakpoints.computer}) {
  //   height: 96px;
  // }
`;

const Logo = styled.img`
  height: 100%;
`;

const ButtonWrapper = styled.div`
    margin-left: 24px;
    margin-right: 0px;
`;

const HeaderWrapper = styled.div`
  width: 100vw;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 96px;
  position: fixed;
  top: 0px;
  z-index: 2000;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: flex;
    position: absolute;
    align-items: center;
    height: 144px;
    padding-left: 8%;
    padding-right: 8%;
    justify-content: space-between;
  }
`;

const Header = (props) => {

  const { userData, signOut } = props;

  const [open, setOpen] = React.useState(false);

  let buttons;

  if (userData) {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button variant="outlined" corners="pill" small to="/subscribe">
            Subscribe
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/account">
            Account
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" onClick={signOut}>
            Sign out
          </Button>
        </ButtonWrapper>
      </Group>
  } else {
    buttons =
      <Group>
        <ButtonWrapper>
          <Button variant="outlined" corners="pill" small to="/subscribe">
            Subscribe
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/sign-up">
            Sign up
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="text" corners="square" to="/sign-in">
            Sign in
          </Button>
        </ButtonWrapper>
      </Group>
  }

  return (
    <HeaderWrapper>
      <LogoWrapper to="/">
        <Logo src="FDV-logo.png" />
      </LogoWrapper>
      {buttons}
      <DrawerWrapper open={open}>
      </DrawerWrapper>
    </HeaderWrapper>
  );
}

export default Header;
