import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "./theme.json";

const Base = styled.button`
  font-family: InterVariable;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.$small ? "14px" : "16px"};
  font-weight: 400;
  height: ${props => props.$small ? "36px" : "48px"};
  padding-left: ${props => props.$small ? "18px" : "24px"};
  padding-right: ${props => props.$small ? "18px" : "24px"};
  cursor: pointer;
  background-clip: padding-box;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
`;

const Outlined = styled(Base)`
  color: ${props => props.$inverted ? "white" : theme.color.button_dark};
  background: unset;
  border: 1.5px solid ${props => props.$inverted ? "white" : theme.color.button_dark};
  &:hover {
    background: ${props => props.$inverted ? "white" : theme.color.button_dark};
    color: ${props => props.$inverted ? theme.color.button_dark : "white"};
  }
`;

const Solid = styled(Base)`
  color: #FFFFFF;
  background: ${props => props.$inverted ? theme.color.white : theme.color.button_dark};
  border: 1.5px solid ${props => props.$inverted ? "white" : theme.color.button_dark};
  &:hover {
    background: ${props => props.$inverted ? theme.color.background : theme.color.button_dark_hover};
    border: 1.5px solid ${props => props.$inverted ? "white" : theme.color.button_dark_hover};
`;

const Text = styled.button`
  font-family: InterVariable;
  font-weight: 400;
  font-size: ${props => props.$small ? "14px" : "16px"};
  cursor: pointer;
  padding: 0px;
  white-space: nowrap;
  background: unset;
  color: ${props => props.$inverted ? "rgba(255, 255, 255, 1)" : theme.color.button_dark};
  padding-top: 5.5px;
  padding-bottom: 4px;
  line-height: 1;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0);
  &:hover {
    border-bottom: 1.5px solid ${props => props.$inverted ? "white" : theme.color.button_dark};
  }
`;

const Button = (props) => {

  let { variant, width, corners, small, to, href, onClick, inverted, children } = props;

  let button;

  let borderRadius;

  if (corners === "square") {
    borderRadius = "0px";
  }

  if (corners === "rounded") {
    borderRadius = "8px";
  }

  if (corners === "pill") {
    borderRadius = "50px";
  }

  if (!width) {
    width = "unset"
  }

  let as = null;
  if (to) {
    as = Link
  }
  if (href) {
    as = "a"
  }

  if (variant === "solid") {
    button =
      <Solid $small={small} $inverted={inverted} as={as} href={href} to={to} style={{ width: width, borderRadius: borderRadius }} onClick={onClick}>
        {children}
      </Solid>
  }

  if (variant === "outlined") {
    button =
      <Outlined $small={small} $inverted={inverted} as={as} href={href} to={to} style={{ width: width, borderRadius: borderRadius }} onClick={onClick}>
        {children}
      </Outlined>
  }

  if (variant === "text") {
    button =
      <Text $small={small} $inverted={inverted} as={as} href={href} to={to} style={{ width: width }} onClick={onClick}>
        {children}
      </Text>
  }

  return (
    button
  )
}

export default Button;
