import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import Section from "./section";
import Card from "./card";
import Chip from "./chip";
import { LargeFeature, MediumFeature, SmallFeature, FeatureDivider, FeatureSpacer } from "./feature-elements";
import { PageWrapper, GridWrapper, TitleWrapper, TitleBannerWrapper, BannerWrapper, ThreeColumnWrapper } from "./wrapper-elements";
import { PageTitle, PageDescription, Highlight } from "./text-components";
import theme from "./theme.json";

const ButtonWrapper = styled.div`
  padding-top: 12px;
`;

const HomePage = (props) => {

  const { articleData, userData } = props;

  let page =
    <PageWrapper>

    </PageWrapper>

  if (articleData) {
    page =
      <PageWrapper>
        <TitleBannerWrapper>
          <TitleWrapper>
            <PageTitle>
              Your visual guide to football
            </PageTitle>
            <PageDescription>
              Keep up with the world's most popular football leagues with <Highlight style={{ background: theme.color.primary.normal }}>AI-powered predictions</Highlight> and <Highlight style={{ background: theme.color.primary.normal }}>intuitive data visualization.</Highlight> Starting at <Highlight style={{ background: theme.color.secondary.normal }}>only $2 a month.</Highlight>
            </PageDescription>
            <ButtonWrapper>
              <Button style="outlined" corners="pill" label="Subscribe" as={Link} to="/subscribe" />
            </ButtonWrapper>
          </TitleWrapper>
        </TitleBannerWrapper>
        <FeatureDivider />
        <LargeFeature
          title="Cutting-edge football data journalism"
          text={[
            "We use intuitive, eye-catching data visualization to make advanced football analytics accessible to all fans. Get all the game-changing insights without all the numbers. From predictions to tactical analysis — we've got you covered. Starting at only $2 a month."
          ]}
          icon="FDV-logo-icon.png"
          buttonLabel="Subscribe"
          buttonLink="/about"
        />
        {/* <ThreeColumnWrapper>
          <MediumFeature
            title="Intuitive data viz"
            text={[
              "Advanced analytics have revolutionized how football is played and analyzed. We use intuitive data viz to make it easy for fans to get into football analytics. No numbers, no tables, no math.  Just easy-to-understand visualizations with explanations in plain English."
            ]}
          />
          <MediumFeature
            title="Affordable subscriptions"
            text={[
              "For just $2, The Fan Package brings you AI-powered predictions for every match, weekly league predictions, and weekly fixture guides. It's perfect if you want to keep up with all the twists and turns in your favorite leagues.",
              "For $5, The Insider package brings you everything in The Fan Package plus in-depth match previews, match breakdowns, and weekly power ratings. It's our most popular package.",
              "For $10, The Analyst Package brings you everything in The Insider Package plus in-depth team and player analysis. It's perfect if you're into tactics and want keep up with the latest in football analytics."
            ]}
          />
        </ThreeColumnWrapper> */}
        <FeatureDivider />
        <ThreeColumnWrapper>
          <LargeFeature
            title="Leagues"
            text={[
              "We currently cover Europe's top 5 leagues — the Premier League (England), La Liga (Spain), the Bundesliga (Germany), Serie A (Italy), and Ligue  (France) — and MLS (United States), and we have plans to expand our coverage to include more leagues.",
              "Europe's top 5 leagues offer a compelling mix of tradition, passion, and elite talent, captivating football fans all over the world. MLS offers competitive, unpredictable matches and compelling regional rivalries. Historical rivalries, electric fan culture, global superstars, tactical master classes — no matter what you're looking for from your football, we've got you covered."
            ]}
            icon="FDV-logo-icon.png"
          />
        </ThreeColumnWrapper>
        <ThreeColumnWrapper>
          <SmallFeature
            title="Premier League"
            text={[
              "The Premier League features global superstars, fiercely competitive matches, and historic clubs. It captivates fans with iconic rivalries."
            ]}
            icon="maps/england-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/premier-league"
          />
          <SmallFeature
            title="La Liga"
            text={[
              "La Liga showcases technical brilliance and captivating style. The legendary El Clásico rivalry between Barcelona and Real Madrid sets the standard."
            ]}
            icon="maps/spain-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/la-liga"
          />
          <SmallFeature
            title="Bundesliga"
            text={[
              "The Bundesliga offers high-intensity football and passionate fan culture. Matches have vibrant atmospheres, and there's a focus on youth development."
            ]}
            icon="maps/germany-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/bundesliga"
          />
          <SmallFeature
            title="Serie A"
            text={[
              "Serie A emphasizes tactical mastery. Featuring iconic clubs with rich footballing hertitage, it attracts fans with legendary rivalries like the Milan derby."
            ]}
            icon="maps/italy-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/serie-a"
          />
          <SmallFeature
            title="Ligue 1"
            text={[
              "Ligue 1 features emerging talent and exciting attacking play. The presence of superstars like Kylian Mbappé and Neymar attract global attention."
            ]}
            icon="maps/france-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/ligue-1"
          />
          <SmallFeature
            title="MLS"
            text={[
              "MLS offers exciting regional rivilries, diverse fan engagement, vibrant stadium experiences, and the greatest player of all time — Lionel Messi."
            ]}
            icon="maps/united-states-map.svg"
            buttonLabel="Latest posts"
            buttonLink="/mls"
          />
        </ThreeColumnWrapper>
        <Section
          articleData={articleData}
          userData={userData}
          title="Latest articles"
          buttonLabel="See more"
          buttonLink="/latest"
          count={12}
        />
        <FeatureDivider />
        <LargeFeature
          title="Coverage"
          text={[
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          ]}
          icon="FDV-logo-icon.png"
        />
        <ThreeColumnWrapper>
          <SmallFeature
            title="Match predictions"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/match-predictions"
          />
          <SmallFeature
            title="League predictions"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/league-predictions"
          />
          <SmallFeature
            title="Fixture guides"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/fixture-guides"
          />
          <SmallFeature
            title="Match previews"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/match-previews"
          />
          <SmallFeature
            title="Match reports"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/match-reports"
          />
          <SmallFeature
            title="Power rankings"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/power-rankings"
          />

          <SmallFeature
            title="Team analysis"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/team-analysis"
          />
          <SmallFeature
            title="Player analysis"
            text={[
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            ]}
            buttonLabel="Latest posts"
            buttonLink="/player-analysis"
          />
        </ThreeColumnWrapper>
        <BannerWrapper>
          <LargeFeature
            title="Learn about football analytics"
            text="Interested in learning more about football analytics but don't know where to start? Take a look at our friendly introduction to football analytics."
            buttonLabel="Get started"
            buttonLink="/guide-to-football-analytics"
          />
        </BannerWrapper>
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match predictions";
            })
          }
          userData={userData}
          title="Match predictions"
          buttonLabel="See more"
          buttonLink="/match-predictions"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "League predictions";
            })
          }
          userData={userData}
          title="League predictions"
          buttonLabel="See more"
          buttonLink="/league-predictions"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Fixture guide";
            })
          }
          userData={userData}
          title="Fixture guides"
          buttonLabel="See more"
          buttonLink="/fixture-guides"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match preview";
            })
          }
          userData={userData}
          title="Match previews"
          buttonLabel="See more"
          buttonLink="/match-previews"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match report";
            })
          }
          userData={userData}
          title="Match reports"
          buttonLabel="See more"
          buttonLink="/match-reports"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Power rankings";
            })
          }
          userData={userData}
          title="Power rankings"
          buttonLabel="See more"
          buttonLink="/power-rankings"
          count={6}
        />
        {/* <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Match analysis";
            })
          }
          userData={userData}
          title="Match analysis"
          buttonLabel="See more"
          buttonLink="/match-analysis"
          count={6}
        /> */}
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Team analysis";
            })
          }
          userData={userData}
          title="Team analysis"
          buttonLabel="See more"
          buttonLink="/team-analysis"
          count={6}
        />
        <Section
          articleData={
            articleData.filter((item) => {
              return item.type === "Player analysis";
            })
          }
          userData={userData}
          title="Player analysis"
          buttonLabel="See more"
          buttonLink="/player-analysis"
          count={6}
        />

      </PageWrapper >
  }

  return (
    page
  )
}

export default HomePage;
