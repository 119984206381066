import React from "react";
import styled from "styled-components";
import { useNavigate, Navigate } from "react-router-dom";
import { PageWrapper, FormWrapper } from "./wrapper-elements";
import { Title, Heading } from "./text-components";
import { FormElement, FormInstructions, FormRow, FormMessage } from "./form-elements";
import Button from "./button";
import Header from "./header";
import Footer from "./footer";
import TextBox from "./text-box";
import theme from "./theme";
import Axios from "axios";

const AccountPage = (props) => {

  const navigate = useNavigate();

  let { API, userData, saveUserData, signOut } = props;

  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordCheck, setNewPasswordCheck] = React.useState("");
  const [newEmail, setNewEmail] = React.useState("");
  const [messageText, setMessageText] = React.useState("");
  const [dialog, setDialog] = React.useState("");

  // let subscription = "none";
  // if (userData) {
  //   if (userData.basic) {
  //     subscription = "The Fan"
  //   }
  //   if (userData.standard) {
  //     subscription = "The Insider"
  //   }
  //   if (userData.premium) {
  //     subscription = "The Analyst"
  //   }
  // }

  const closeDialog = () => {
    setDialog("");
    setPassword("");
    setNewPassword("");
    setNewPasswordCheck("");
    setNewEmail("");
    setMessageText("");
  }

  const signInAgain = (email, password) => {
    console.log("logging in again: " + email + ", " + password)
    Axios({
      method: "post",
      url: API + "/user/sign-in",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "email": email,
        "password": password,
      }
    })
      .then(response => {
        saveUserData(response.data);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data.error)
        }
      });
  }

  const deleteAccount = () => {
    Axios({
      method: "post",
      url: API + "/user/delete-account",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "authorization": userData.token,
      },
      data: {
        "password": password,
      }
    })
      .then(response => {
        closeDialog();
        signOut();
      })
      .catch(error => {
        setMessageText(error.response.data.error);
        console.log(error.response.data.error)
      });
  }

  const changePassword = () => {
    if (newPassword === newPasswordCheck) {
      Axios({
        method: "post",
        url: API + "/user/change-password",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*",
          "authorization": userData.token,
        },
        data: {
          "password": password,
          "new_password": newPassword,
        }
      })
        .then(response => {
          // console.log(response.data);
          // saveUserData(response.data);
          closeDialog();
          signInAgain(response.data.email, newPassword);
          setMessageText("Your password was changed.")
        })
        .catch(error => {
          setMessageText(error.response.data.error);
          console.log(error.response.data.error)
        });
    } else {
      setMessageText("The passwords don't match.")
    }
  }

  const changeEmail = () => {
    Axios({
      method: "post",
      url: API + "/user/change-email",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "authorization": userData.token,
      },
      data: {
        "password": password,
        "email": userData.email,
        "new_email": newEmail,
      }
    })
      .then(response => {
        // console.log(response.data);
        // saveUserData(response.data);
        closeDialog();
        signInAgain(newEmail, password);
        setMessageText("Your email was changed.")
      })
      .catch(error => {
        setMessageText(error.response.data.error);
        console.log(error.response.data.error)
      });
  };

  let message;

  if (messageText !== "") {
    message =
      <FormMessage>
        {messageText}
      </FormMessage>
  }

  let page;

  if (userData) {

    page =
      <PageWrapper>
        <FormWrapper>
          <Heading>
            Hi, {userData.first_name}!
          </Heading>
          {/* <FormInstructions>
            What can we help you with today?
          </FormInstructions> */}
          <FormRow>
            <FormElement>
              <Button variant="outlined" corners="rounded" width="340px" onClick={() => { }} to="/">
                Manage subscription
              </Button>
            </FormElement>
          </FormRow>
          <FormRow>
            <FormElement>
              <Button variant="outlined" corners="rounded" width="340px" onClick={() => setDialog("change email")}>
                Change email
              </Button>
            </FormElement>
          </FormRow>
          <FormRow>
            <FormElement>
              <Button variant="outlined" corners="rounded" width="340px" onClick={() => setDialog("change password")}>
                Change password
              </Button>
            </FormElement>
          </FormRow>
          <FormRow>
            <FormElement>
              <Button variant="outlined" corners="rounded" width="340px" onClick={() => setDialog("delete account")}>
                Delete account
              </Button>
            </FormElement>
          </FormRow>
          <FormRow>
            <FormElement>
              <Button variant="outlined" corners="rounded" width="340px" href="mailto:support@hjartdalstudios.com">
                Contact us
              </Button>
            </FormElement>
          </FormRow>
          {message}
        </FormWrapper>
      </PageWrapper>

    if (dialog === "delete account") {
      page =
        <PageWrapper>
          <FormWrapper>
            <Heading>
              Delete account
            </Heading>
            <FormInstructions>
              Are you sure you want to delete your account? This can't be undone. If you're sure, please confirm your password.
            </FormInstructions>
            <FormElement>
              <TextBox type="password" label="Password" value={password} onChange={setPassword}/>
            </FormElement>
            <FormRow>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={deleteAccount}>
                  Delete account
                </Button>
              </FormElement>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={closeDialog}>
                  Cancel
                </Button>
              </FormElement>
            </FormRow>
            {message}
          </FormWrapper>
        </PageWrapper>
    }

    if (dialog === "change password") {
      page =
        <PageWrapper>
          <FormWrapper>
            <Heading>
              Change password
            </Heading>
            <FormInstructions>
              Please enter your current password and enter and confirm a new password.
            </FormInstructions>
            <FormElement>
              <TextBox type="password" label="Current password" value={password} onChange={setPassword} />
            </FormElement>
            <FormElement>
              <TextBox type="password" label="New password" note="Passwords must have at least 8 characters and have at least one lower case letter, one uppercase letter, one number, and one special character." value={newPassword} onChange={setNewPassword} />
            </FormElement>
            <FormElement>
              <TextBox type="password" label="Confirm new password" value={newPasswordCheck} onChange={setNewPasswordCheck} />
            </FormElement>
            <FormRow>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={changePassword}>
                  Change password
                </Button>
              </FormElement>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={closeDialog}>
                  Cancel
                </Button>
              </FormElement>
            </FormRow>
            {message}
          </FormWrapper>
        </PageWrapper >
    }

    if (dialog === "change email") {
      page =
        <PageWrapper>
          <FormWrapper>
            <Heading>
              Change email
            </Heading>
            <FormInstructions>
              Please enter your current password and a new email address. You will recieve an email with a link to verify the new address.
            </FormInstructions>
            <FormElement>
              <TextBox type="password" label="Password" value={password} onChange={setPassword} />
            </FormElement>
            <FormElement>
              <TextBox type="text" label="New email" value={newEmail} onChange={setNewEmail} />
            </FormElement>
            <FormRow>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={changeEmail}>
                  Change email
                </Button>
              </FormElement>
              <FormElement>
                <Button variant="outlined" corners="rounded" onClick={closeDialog}>
                  Cancel
                </Button>
              </FormElement>
            </FormRow>
            {message}
          </FormWrapper>
        </PageWrapper>
    }
  } else {
    page =
      <Navigate replace to="/sign-in" />
  }

  return page
}

export default AccountPage;
