import styled from "styled-components";
import theme from "./theme.json";

const PageWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 96px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-top: 144px;
  }
`;

const SectionWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`;

const GridWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: flex-start;
  overflow: visible;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    width: calc(${theme.breakpoints.tablet} - 24px);
    margin-bottom: 48px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    width: calc(${theme.breakpoints.computer} - 24px);
  }
  &::-webkit-scrollbar { 
    display: none;
  }
`;

const GridRowWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100vw;
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    // width: ${theme.breakpoints.tablet};
    padding-left: 48px;
    padding-right: 48px;
    margin-bottom: 48px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    // width: ${theme.breakpoints.computer};
    padding-left: 96px;
    padding-right: 96px;
  }
  &::-webkit-scrollbar { 
    display: none;
  }
`;

const FeatureWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 372px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 888px) {
    width: 696px;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1248px) {
    width: 1056px;
    padding-right: calc(336px + 24px);
  }
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  width: 372px;
  @media (min-width: 888px) {
    width: 744px;
  }
  // @media (min-width: 1248px) {
  //   width: 1116px;
  // }
`;

const BannerWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.background};
  border-radius: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-top: ${theme.spacing.twelve_units};
    padding-bottom: ${theme.spacing.twelve_units};
    margin-top: ${theme.spacing.four_units};
    margin-bottom: ${theme.spacing.four_units};
    width: calc(100% - ${theme.spacing.eight_units});
  }
`;

const TitleBannerWrapper = styled(BannerWrapper)`
  margin-top: 0px;
  margin-bottom: ${theme.spacing.four_units};
`;

const TitleWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100vw;
  padding-left: 24px;
  padding-right: 24px;
  background-color: white;
  @media (min-width: ${theme.breakpoints.computer}) {
    width: 90vw;
  }
`;

const ArticleWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    margin-bottom: 24px;
    max-width: ${theme.breakpoints.tablet};
  }
  // @media (min-width: ${theme.breakpoints.computer}) {
  //   margin-bottom: 48px;
  // }
`;

const TextWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(${theme.widths.column} * 2);
  overflow: hidden;
  @media (min-width: ${theme.breakpoints.tablet}) {
    width: calc(${theme.widths.column} * 2 - ${theme.spacing.two_units});
  }
`;

const PlotWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 16px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 24px;
  width: 100%;
  cursor: pointer;
  background-color: white;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 24px;
    margin-bottom: 48px;
    width: calc(${theme.breakpoints.tablet} - 48px);
    // transform: scale(1);
    // transition: 0.2s ease-in-out;
    // &:hover {
    //   transform: scale(1.03);
    //   box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
    // }
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    padding: 24px;
    margin-bottom: 96px;
    width: calc(${theme.breakpoints.computer} - 48px);
  }
`;

const FooterWrapper = styled(BannerWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.color.neutral.dark};
  padding-top: ${theme.spacing.eight_units};
  padding-bottom: ${theme.spacing.eight_units};
  margin-top: ${theme.spacing.four_units};
  margin-bottom: 0px;
`;

const HeaderWrapper = styled.div`
  width: calc(100vw - ${theme.spacing.sixteen_units});
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(${theme.spacing.twelve_units});
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  width: 340px;
`;

const FormElementWrapper = styled.div`
  margin-bottom: ${theme.spacing.two_units};
`;

const MessageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.color.text};
  border-radius: 8px;
  background-color: ${theme.color.primary};
  border: 1.5px solid ${theme.color.primary};
  line-height: 1.3;
`;

const OneColumnWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: ${theme.widths.column};
  max-width: ${theme.widths.column};
  padding-left: ${theme.spacing.one_unit};
  padding-right: ${theme.spacing.one_unit};
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TwoColumnWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: ${theme.widths.column};
  max-width: ${theme.widths.column};
  padding-left: ${theme.spacing.one_unit};
  padding-right: ${theme.spacing.one_unit};
  @media (min-width: ${theme.breakpoints.tablet}) {
    min-width: calc(${theme.widths.column} * 2);
    max-width: calc(${theme.widths.column} * 2);
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ThreeColumnWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: calc(${theme.widths.column} - ${theme.spacing.one_unit});
  max-width: calc(${theme.widths.column} - ${theme.spacing.one_unit});
  padding-left: ${theme.spacing.one_unit};
  padding-right: ${theme.spacing.one_unit};
  @media (min-width: ${theme.breakpoints.tablet}) {
    min-width: calc(${theme.widths.column} * 2);
    max-width: calc(${theme.widths.column} * 2);
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: ${theme.breakpoints.computer}) {
    min-width: calc(${theme.widths.column} * 3);
    max-width: calc(${theme.widths.column} * 3);
  }
`;

export { PageWrapper, GridWrapper, GridRowWrapper, SectionWrapper, ContentWrapper, FeatureWrapper, TitleWrapper, BannerWrapper, TitleBannerWrapper, ArticleWrapper, TextWrapper, PlotWrapper, HeaderWrapper, FooterWrapper, FormElementWrapper, FormWrapper, MessageWrapper, OneColumnWrapper, TwoColumnWrapper, ThreeColumnWrapper }; 
