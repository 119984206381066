import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./button";
import Section from "./section";
import Card from "./card";
import Chip from "./chip";
import { Block, BlockSeparator } from "./block-elements";
import { LargeFeature, MediumFeature, SmallFeature, FeatureDivider, FeatureSpacer } from "./feature-elements";
import { PageWrapper, TitleWrapper, GridRowWrapper, SectionWrapper } from "./wrapper-elements";
import { ChipSection, ChipTitle, ChipRow, ChipWrapper } from "./chip-components";
import { PageTitle, PageDescription, Highlight, SectionTitleRow } from "./text-components";
import theme from "./theme.json";

const ButtonWrapper = styled.div`
  padding-top: 24px;
`;

const HomePage = (props) => {

  const { articleData, userData } = props;

  let page =
    <PageWrapper>

    </PageWrapper>

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY - 48;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  if (articleData) {
    page =
      <PageWrapper>
        <Block backgroundColor="white">
          <TitleWrapper>
            <PageTitle>
              Your visual guide to football
            </PageTitle>
            <PageDescription>
              Keep up with the world's most popular football leagues with ML-powered predictions and intuitive data visualization. Starting at only <Highlight style={{ background: theme.color.primary.normal }}>$2 a month.</Highlight>
            </PageDescription>
            <ButtonWrapper>
              <Button variant="solid" corners="pill" as={Link} to="/subscribe">
                Subscribe
              </Button>
            </ButtonWrapper>
          </TitleWrapper>
        </Block>
        <BlockSeparator shape={2} topColor="white" bottomColor="light" />
        <Block backgroundColor="light">
          <ChipSection>
            <ChipTitle>
              Coverage
            </ChipTitle>
            <ChipRow>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/match-predictions">
                  Match predictions
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/league-predictions">
                  League predictions
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/fixture-guides">
                  Fixture guides
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/match-previews">
                  Match previews
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/match-reports">
                  Match reports
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/power-rankings">
                  Power rankings
                </Button>
              </ChipWrapper>
            </ChipRow>
          </ChipSection>
          <ChipSection>
            <ChipTitle>
              Leagues
            </ChipTitle>
            <ChipRow>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/premier-league">
                  Premier League
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/la-liga">
                  La Liga
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/bundesliga">
                  Bundesliga
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/serie-a">
                  Serie A
                </Button>
              </ChipWrapper>
              <ChipWrapper>
                <Button variant="outlined" corners="pill" small to="/ligue-1">
                  Ligue 1
                </Button>
              </ChipWrapper>
            </ChipRow>
          </ChipSection>
          <Section
            id="match-predictions"
            articleData={
              articleData.filter((item) => {
                return item.type === "Match predictions";
              })
            }
            userData={userData}
            title="Match predictions"
            link="/match-predictions"
            count={10}
          />
          <Section
            id="league-predictions"
            articleData={
              articleData.filter((item) => {
                return item.type === "League predictions";
              })
            }
            userData={userData}
            title="League predictions"
            link="/league-predictions"
            count={5}
          />
          <Section
            id="fixture-guides"
            articleData={
              articleData.filter((item) => {
                return item.type === "Fixture guide";
              })
            }
            userData={userData}
            title="Fixture guides"
            link="/fixture-guides"
            count={10}
          />
          <Section
            id="match-previews"
            articleData={
              articleData.filter((item) => {
                return item.type === "Match preview";
              })
            }
            userData={userData}
            title="Match previews"
            link="/match-previews"
            count={10}
          />
          <Section
            id="match-reports"
            articleData={
              articleData.filter((item) => {
                return item.type === "Match report";
              })
            }
            userData={userData}
            title="Match reports"
            link="/match-reports"
            count={10}
          />
          <Section
            id="power-rankings"
            articleData={
              articleData.filter((item) => {
                return item.type === "Power rankings";
              })
            }
            userData={userData}
            title="Power rankings"
            link="/power-rankings"
            count={5}
          />
          {/* <Section
            articleData={
              articleData.filter((item) => {
                return item.type === "Team analysis";
              })
            }
            userData={userData}
            title="Team analysis"
            link="/team-analysis"
            count={6}
          /> */}
          {/* <Section
            articleData={
              articleData.filter((item) => {
                return item.type === "Player analysis";
              })
            }
            userData={userData}
            title="Player analysis"
            link="/player-analysis"
            count={6}
          /> */}
        </Block>
        <BlockSeparator shape={3} topColor="light" bottomColor="dark" flipY={true} />
      </PageWrapper >
  }

  return (
    page
  )
}

export default HomePage;
