import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: fixed;
    z-index: 1500;
    left: 0px;
    top: 192px;
    bottom: 144px;
    transition: opacity 0.3s;
  }
`;

const DotGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
`;

const Dot = styled.div`
  // width: 12px;
  // height: 12px;
  // background-color: #1D385E;
  // border-radius: 50%;
  // transition-duration: 0.3s;
  // margin-top: 12px;
  // margin-bottom: 12px;
  // cursor: pointer;
  transition: 0.3s ease-in-out;
  transition-delay: 0.1s;
  width: 12px;
  height: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: black;
  margin-top: 12px;
  margin-bottom: 12px; 
`;

const DotLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #808080;
  font-size: 12px;
  font-weight: 700;
  transition-duration: 0.3s;
  line-height: 1;
  margin-top: 4px;
  margin-right: 16px;
  border-radius: 4px;
  pointer-events: none;
  color: #808080;
`;

const DotNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
  // padding-left: 24px;
  // padding-right: 16px;
  width: 24px;
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  transition-delay: 0.1s;
`;

const GetVisibility = (id) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    var element = document.getElementById(id);
    if (element != null) {
      var top = element.getBoundingClientRect().top + (window.innerHeight / 2);
      var bottom = element.getBoundingClientRect().bottom - (window.innerHeight / 2);
      setVisible(top < window.innerHeight && bottom > 0);
    }
  }, [scrollTop]);
  return visible;
}

const scroll = (id, offset) => {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.scrollY + offset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

const NavDots = (props) => {

  const { data } = props;

  const [dotHovered, setDotHovered] = React.useState(null);

  const scrolling = React.useRef(null);

  const dots =
    <Wrapper>
      {data.map((dot, i) => (
        <DotGroup key={i} >
          <Dot
            onMouseEnter={() => setDotHovered(dot.id)}
            onMouseLeave={() => setDotHovered("")}
            // style={{ "translate: ": GetVisibility(dot.id) && !scrolling.current ? 1 : 0 }}
            style={{
              "transform": GetVisibility(dot.id) && !scrolling.current ? "translate(0px, 0px)" : "translate(-24px, 0px)"
            }}
          />
          <DotNumber
            onClick={() => scroll(dot.id, -48)}
            style={{
              "color": GetVisibility(dot.id) && !scrolling.current ? "black" : "rgba(0, 0, 0, 0.2)"
            }}
          >
            {i + 1}
          </DotNumber>
          {/* <DotLabel style={{ "opacity": dotHovered == dot.id ? 1 : 0 }}>
            {dot.label}
          </DotLabel> */}
        </DotGroup>
      ))}
    </Wrapper>

  return dots;
};

export default NavDots;
